import PageLayouts from 'src/components/layout/PageLayouts'
import { Col, Row } from 'react-bootstrap'
import { useLayoutEffect, useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import { ITabs } from 'src/type'
import { PageLink, VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED } from 'src/constants'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { IClass } from 'src/type/classes'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import { Select } from 'antd'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import AddCourseToClass from 'src/components/classes/add-course/AddCourseToClass'
import { ClassesApi } from 'src/apis/classes'
import { getAPIUser } from 'src/apis/user'
import {
  TITLE_OPTIONS_CLASS,
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_TYPE_CLASS,
  OPTIONS_OF_DURATION,
  categoryCourseType,
  OPTIONS_OF_STATUS,
  CONFIRM_CHANGE_COURE,
} from 'src/constants/classes'
import ListUser from 'src/components/classes/create/ListUser'
import ErrorMessage from 'src/common/ErrorMessage'
import AddExam from 'src/components/classes/add-exam/AddExam'
import { toast } from 'react-hot-toast'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import { isEmpty } from 'lodash'
import { format } from 'date-fns'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import SappLabel from 'src/components/base/label/SappLabel'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { IClassType } from 'src/type/courses'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ListMentor from 'src/components/classes/create/ListMentor'
import ListLecture from 'src/components/classes/create/ListLecture'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'

const defaultValues = {
  name: '',
  code: '',
  status: '',
  area_id: '',
  instruction_mode: '',
  type: '',
  description: '',
}

const CreateClassInfo = () => {
  const { Option } = Select
  const navigate = useNavigate()
  const location = useLocation()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const [checkedListCourse, setCheckedListCourse] = useState<any>([])
  const [openAddCourse, setOpenAddCourse] = useState<boolean>(false)
  const [typeClass, setTypeClass] = useState<string>('')
  const [selectedRadio, setSelectedRadio] = useState<string>(`${OPTIONS_OF_DURATION.valueFixed}`)
  const [areaClasses, setAreaClasses] = useState<any[]>([])
  const [user, setUser] = useState<any>([])
  const listUserRef = useRef<any>(null)
  const [openAddExam, setOpenAddExam] = useState<{ status: boolean; type: string }>({
    status: false,
    type: '',
  })
  const [checkedListExam, setCheckedListExam] = useState<any>([])
  const [validateCourse, setValidateCourse] = useState<boolean>(false)
  const [classId, setClassId] = useState<string>('')
  const [classDetail, setClassDetail] = useState<IClass>()
  const [loadTeacher, setLoadTeacher] = useState<boolean>(false)

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.CLASSES}`,
      title: `${TITLE_OPTIONS_CLASS.listClasses}`,
    },
    {
      link: '',
      title: `${classId ? TITLE_OPTIONS_CLASS.updateClass : TITLE_OPTIONS_CLASS.createClass}`,
    },
  ]

  const [typeInstruction, setTypeInstruction] = useState('')

  const validationSchema = {
    name: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    code: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Code', 1000)),
    status: z.string({ required_error: VALIDATION_FILED }).optional(),
    // area_id: z
    //   .string({required_error: VALIDATION_FILED})
    //   .trim()
    //   .min(1, VALIDATION_FILED),
    instruction_mode: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED),
    type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    capacity: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    lecture_id: z.string({ required_error: VALIDATION_FILED }).optional(),
    mentor_id: z.string({ required_error: VALIDATION_FILED }).optional(),
    duration_type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    description: z.string().optional(),
    ...(selectedRadio === 'FIXED' && {
      duration_at: z
        .object({
          fromDate: z.date(),
          toDate: z.date(),
        })
        .optional()
        .refine((data) => data?.fromDate && data?.toDate, VALIDATION_FILED),
    }),
    ...(selectedRadio === 'FLEXIBLE' && {
      flexible_days: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    }),
  }

  const schemaOnline = z.object(
    Object.assign({}, validationSchema, {
      area_id: z.any(),
    })
  )

  const schemaOffline = z.object(
    Object.assign({}, validationSchema, {
      area_id: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    })
  )

  const validateRequiredArea = typeInstruction !== 'ONLINE'

  const useFormProp = useForm<IClass>({
    resolver: zodResolver(validateRequiredArea ? schemaOffline : schemaOnline),
    mode: 'onSubmit',
    defaultValues,
  })

  const { handleSubmit, getValues, control, setValue } = useFormProp

  const onSubmit = async (data: IClass) => {
    setLoading(true)

    let request: IClass = {
      name: data.name,
      code: data.code,
      status: data.status || 'DRAFT',
      area_id: data.area_id || undefined,
      instruction_mode: data.instruction_mode,
      type: data.type,
      capacity: Number(data.capacity),
      duration_type: data.duration_type,
      excepted_course_section: [],
      description: data?.description ?? '',
      course_id: null,
    }

    if (data.duration_type === 'FLEXIBLE' && data.flexible_days) {
      const getThisDay = new Date()
      const startAt = getThisDay.toISOString()
      const openAt = getThisDay.toISOString()
      const finishAt = new Date(
        getThisDay.setDate(getThisDay.getDate() + data.flexible_days)
      ).toISOString()
      const flexibleDays = {
        flexible_days: Number(data.flexible_days),
        opening_at: openAt,
        started_at: startAt,
        finished_at: finishAt,
      }
      Object.assign(request, flexibleDays)
    } else {
      const objectDateFixed = {
        opening_at:
          getValues('opening_at') !== null ? getValues('opening_at') : data?.duration_at.fromDate,
        started_at: data?.duration_at.fromDate,
        finished_at: data?.duration_at.toDate,
        flexible_days: 1,
      }
      Object.assign(request, objectDateFixed)
    }

    if (checkedListCourse[0]?.id !== undefined) {
      Object.assign(request, { course_id: checkedListCourse[0]?.id })
    }

    let objectListExam = {}
    if (checkedListExam[0]?.type === 'CFA') {
      objectListExam = { cfa_examination_id: `${checkedListExam[0]?.id}` }
    } else if (checkedListExam[0]?.type === 'ACCA') {
      objectListExam = { acca_examination_id: `${checkedListExam[0]?.id}` }
    } else if (checkedListExam[0]?.type === 'CMA') {
      objectListExam = { cma_examination_id: `${checkedListExam[0]?.id}` }
    } else {
      objectListExam = {
        cfa_examination_id: null,
        acca_examination_id: null,
        cma_examination_id: null,
      }
    }
    Object.assign(request, objectListExam)
    try {
      if (classId) {
        Object.assign(request, { id: classId })
        if (
          classDetail?.course_id !== request.course_id &&
          classDetail?.class_lectures &&
          classDetail?.class_lectures?.length > 0
        ) {
          confirm({
            okButtonCaption: 'Yes',
            cancelButtonCaption: 'No',
            body: [CONFIRM_CHANGE_COURE],
            onClick: async () => {
              request.lectures = []
              const res = await ClassesApi.updateClass(request)
              if (res) {
                setClassDetail(res.data)
                toast.success('Class updated successfully!')
              }
              setLoadTeacher(true)
            },
            onClose: () => {
              return
            },
          })
        } else {
          const res = await ClassesApi.updateClass(request)
          if (res) {
            setClassDetail(res.data)
            toast.success('Class updated successfully!')
          }
        }
      } else {
        const res = await ClassesApi.createClass({ data: { ...request } })
        if (res) {
          toast.success('Class saved successfully!')
          setClassId(res?.data?.id || '')
          setClassDetail(res.data)
        }
      }
    } catch (error: any) {
      // do nothing
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  const fetchClass = async () => {
    if (!classId) return
    const res = await ClassesApi.detail(classId)
    if (res) {
      setClassDetail(res.data)
    }
  }

  useEffect(() => {
    setCheckedListExam([])
  }, [checkedListCourse])

  useEffect(() => {
    if (classId && listUserRef?.current) {
      listUserRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [classId])

  const handleChangeType = () => {
    setCheckedListCourse([])
  }

  const fetchAreaClasses = async ({ page_index, page_size }: any) => {
    try {
      const res = await ClassesApi.getAreaClasses({ page_index: page_index, page_size: page_size })
      setAreaClasses(res?.data?.areas)
    } catch (error) {
      // do nothing
    }
  }

  useLayoutEffect(() => {
    fetchAreaClasses({ page_index: 1, page_size: 20 })
  }, [])

  const handleChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.currentTarget.value)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${PageLink.CLASSES + (location.search || '')}`),
    })
  }

  useEffect(() => {
    if (classId) {
      setValue('status', 'DRAFT')
    }
  }, [classId])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        setValidateCourse(true)
        handleSubmit((data: any) => {
          if (
            checkedListCourse[0]?.id ||
            user?.length === 0 ||
            getValues('type') === IClassType.TRIAL
          ) {
            onSubmit(data)
          }
        })()
      }}
    >
      <CreateEditLayout
        customPrimaryButton={
          <ButtonPrimary type='submit' title='Save' loading={loading} isListScreen />
        }
        onCancel={handleCancel}
        loading={loading}
        pageTitle={classId ? TITLE_OPTIONS_CLASS.updateClass : TITLE_OPTIONS_CLASS.createClass}
      >
        <div>
          {contextHolder}
          <div className='card-body border-0 d-block px-10 py-0'>
            <div className='class_name card-title d-flex flex-wrap mb-8 w-100'>
              <HookFormTextField
                label={TITLE_OPTIONS_CLASS.name}
                required
                control={control}
                name='name'
                placeholder=' '
              ></HookFormTextField>
            </div>
            <Row>
              <Col md={classId ? 6 : 12} className='mb-8'>
                <HookFormTextField
                  label={TITLE_OPTIONS_CLASS.code}
                  required
                  control={control}
                  name='code'
                  placeholder=' '
                ></HookFormTextField>
              </Col>
              {classId && (
                <Col md={6} className='mb-8'>
                  <HookFormSelectAntd
                    required
                    name='status'
                    control={control}
                    placeholder=' '
                    label={TITLE_OPTIONS_CLASS.status}
                    classNameHeight='sapp-h-45px'
                  >
                    {OPTIONS_OF_STATUS?.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                <HookFormSelectAntd
                  required
                  name='instruction_mode'
                  control={control}
                  placeholder=' '
                  classNameHeight='sapp-h-45px'
                  label={TITLE_OPTIONS_CLASS.constructionMode}
                  onChange={(e: unknown) => setTypeInstruction(e as any)}
                >
                  {OPTIONS_OF_CONTRUCTION_MODE?.map((item) => {
                    return (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </Col>
              <Col md={6} className='mb-8'>
                <HookFormSelectAntd
                  required={validateRequiredArea}
                  name='area_id'
                  control={control}
                  placeholder=' '
                  label={TITLE_OPTIONS_CLASS.area}
                  allowClear
                  classNameHeight='sapp-h-45px'
                >
                  {areaClasses?.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </Col>
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                <HookFormSelectAntd
                  required
                  name='type'
                  control={control}
                  placeholder=' '
                  onChange={() => {
                    handleChangeType()
                  }}
                  label={TITLE_OPTIONS_CLASS.type}
                  classNameHeight='sapp-h-45px'
                >
                  {OPTIONS_OF_TYPE_CLASS?.map((item) => {
                    return (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </Col>
              <Col md={6} className='mb-8'>
                <HookFormTextField
                  label={TITLE_OPTIONS_CLASS.maximumStudents}
                  required
                  control={control}
                  name='capacity'
                  placeholder=' '
                  type='number'
                  minNumber={1}
                  requiredZero={true}
                ></HookFormTextField>
              </Col>
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                <SappLabel required label={TITLE_OPTIONS_CLASS.duration} />
                <HookFormRadioGroup
                  direction='horizontal'
                  separator={false}
                  name='duration_type'
                  control={control}
                  justify='start'
                  gap={32}
                  labelClass='fw-semibold fs-6 sapp-table-class-field'
                  onChange={handleChangeDuration}
                  defaultValue={`${OPTIONS_OF_DURATION.valueFixed}`}
                  options={[
                    {
                      label: `${OPTIONS_OF_DURATION.labelFixed}`,
                      value: `${OPTIONS_OF_DURATION.valueFixed}`,
                    },
                    {
                      label: `${OPTIONS_OF_DURATION.labelFlexible}`,
                      value: `${OPTIONS_OF_DURATION.valueFlexible}`,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              {selectedRadio === `${OPTIONS_OF_DURATION.valueFixed}` && (
                <>
                  <Col md={6} className='mb-8'>
                    <HookFormDateTime
                      control={control}
                      name='opening_at'
                      placeholder='Opening Date'
                    />
                  </Col>
                  <Col md={6} className='mb-8'>
                    <RangeDateTimePicker control={control} name='duration_at' allowClear={false} />
                  </Col>
                </>
              )}
              {selectedRadio === `${OPTIONS_OF_DURATION.valueFlexible}` && (
                <Col md={6} className='mb-8'>
                  <div className='position-relative'>
                    <HookFormTextField
                      required
                      control={control}
                      name='flexible_days'
                      placeholder=' '
                      type='number'
                      postFix={<div className='px-2'>days</div>}
                      minNumber={1}
                      maxNumber={100000}
                      requiredZero={true}
                    ></HookFormTextField>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              {/* start:: course*/}
              {!isEmpty(getValues('type')) && (
                <Col md={12} className='mb-8'>
                  <label className='sapp-fs-14 fw-bold form-label'>
                    <span
                      className={
                        getValues('type') !== IClassType.TRIAL && user?.length > 0 ? 'required' : ''
                      }
                    >
                      {TITLE_OPTIONS_CLASS.course}
                    </span>
                  </label>
                  <div className='position-relative'>
                    <ButtonIcon
                      title={`${checkedListCourse[0]?.id !== undefined ? ' ' : 'Add Course'}`}
                      className='d-flex justify-content-start align-items-center w-100'
                      customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 position-relative'
                      type='button'
                      onClick={() => {
                        setOpenAddCourse(true)
                        setTypeClass(getValues('type'))
                      }}
                    >
                      {checkedListCourse[0]?.id !== undefined ? (
                        <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                          {checkedListCourse[0]?.name}
                        </div>
                      ) : (
                        <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                      )}
                      <KTIcon
                        iconName='notepad-edit'
                        className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${
                          checkedListCourse[0]?.id !== undefined ? 'first' : ''
                        }`}
                        iconType='outline'
                      />
                    </ButtonIcon>
                    {checkedListCourse[0]?.id !== undefined && (
                      <div className='sapp-icon-last'>
                        <ButtonIconOnly
                          iconName='trash'
                          onClick={handleChangeType}
                          iconType='outline'
                          bg={'sapp-custom-bg-transparent'}
                          activeColor='danger'
                          iconGrayColor={'500'}
                        />
                      </div>
                    )}
                  </div>
                  <>
                    {checkedListCourse[0]?.id === undefined &&
                      validateCourse &&
                      getValues('type') !== IClassType.TRIAL &&
                      user?.length > 0 && (
                        <ErrorMessage>Course must contain at least 1 element</ErrorMessage>
                      )}
                  </>
                </Col>
              )}
              {/* end:: course*/}
            </Row>
            {checkedListCourse[0]?.id !== undefined &&
              categoryCourseType.some((e: any) =>
                e.includes(checkedListCourse[0]?.course_categories[0]?.name)
              ) && (
                <Row>
                  {/* start:: exam*/}
                  <Col md={12} className='mb-8'>
                    <label className='sapp-fs-14 fw-bold form-label'>
                      <span
                        className={
                          getValues('type') !== IClassType.TRIAL && user?.length > 0
                            ? 'required'
                            : ''
                        }
                      >
                        Exam
                      </span>
                    </label>
                    <div className='position-relative'>
                      <ButtonIcon
                        title={`${checkedListExam[0]?.id !== undefined ? ' ' : 'Exam'}`}
                        className='d-flex justify-content-start align-items-center w-100'
                        customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 position-relative'
                        type='button'
                        onClick={() => {
                          setOpenAddExam({ status: true, type: '' })
                        }}
                      >
                        {checkedListExam[0]?.id !== undefined ? (
                          <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                            {(() => {
                              const date = new Date(checkedListExam[0]?.season || null)
                              return `${format(date, 'dd/MM/yyyy')}`
                            })() ?? '-'}
                          </div>
                        ) : (
                          <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                        )}
                        <div
                          className={`sapp-icon-last ${
                            checkedListExam[0]?.id !== undefined ? 'first' : ''
                          }`}
                        >
                          <KTIcon
                            iconName='notepad-edit'
                            className='fs-1 text-gray-500 ps-5 pe-1'
                            iconType='outline'
                          />
                        </div>
                      </ButtonIcon>
                      {checkedListExam[0]?.id !== undefined && (
                        <div className='sapp-icon-last'>
                          <ButtonIconOnly
                            iconName='trash'
                            onClick={() => {
                              setCheckedListExam([])
                            }}
                            iconType='outline'
                            bg={'sapp-custom-bg-transparent'}
                            activeColor='danger'
                            iconGrayColor={'500'}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* end:: exam*/}
                </Row>
              )}
            <Row>
              <Col md={12} className='mb-8'>
                <label className='sapp-fs-14 fw-bold form-label'>
                  <span>{TITLE_OPTIONS_CLASS.describe}</span>
                </label>
                <HookFormEditor
                  height={300}
                  placeholder=' '
                  name='description'
                  control={control}
                  math={true}
                  className='w-100 fs-6'
                  resourceLocation={RESOURCE_LOCATION.COURSE}
                  object_id={undefined}
                />
              </Col>
            </Row>
          </div>
          <AddCourseToClass
            open={openAddCourse}
            setOpen={setOpenAddCourse}
            checkedList={checkedListCourse}
            setCheckedList={setCheckedListCourse}
            typeClass={typeClass}
          />
          <AddExam
            open={openAddExam.status}
            setOpen={setOpenAddExam}
            courseId={checkedListCourse[0]?.id}
            course_category_id={checkedListCourse?.[0]?.course_categories?.[0]?.id}
            course_category_type={checkedListCourse?.[0]?.course_categories?.[0]?.name}
            checkedList={checkedListExam}
            setCheckedList={setCheckedListExam}
          />
          {/* List metor sẽ xuất hiện sau khi thêm class thành công*/}
          {classId && (
            <div className='card mt-2 mt-xl-8 mt-10' ref={listUserRef}>
              <ListMentor title='Danh sách trợ giảng' classId={classId} />
            </div>
          )}
          {/* List lecture sẽ xuất hiện sau khi thêm class thành công*/}
          {classId && classDetail?.course_id && (
            <div className='card mt-2 mt-xl-8' ref={listUserRef}>
              <ListLecture
                title='Danh sách giảng viên'
                classId={classId}
                fetchClass={fetchClass}
                loadTeacher={loadTeacher}
              />
            </div>
          )}
          {/* List user sẽ xuất hiện sau khi thêm class thành công*/}
          {classId && (
            <div className='card mt-2 mt-xl-8' ref={listUserRef}>
              <ListUser
                title='Danh sách học viên'
                getValues={getValues}
                classId={classId}
                setUserStudent={setUser}
              />
            </div>
          )}
        </div>
      </CreateEditLayout>
    </form>
  )
}

export default CreateClassInfo
