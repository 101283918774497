import clsx from 'clsx'
import {KTIcon} from 'src/_metronic/helpers'

interface IProps {
  title?: string
  className?: string
  trigger?: 'click'
  placement?: 'bottom-end'
  toggle?: 'modal'
  target?: string
  customButton?: string
  classTitle?: string
  size?: 'large' | 'middle' | 'small'
}

const ButtonLoading = ({
  title,
  className = '',
  trigger,
  placement,
  target,
  toggle,
  customButton,
  classTitle,
  size = 'small',
}: IProps) => {
  return (
    <button
      className={clsx(
        `${className} btn`,
        customButton || 'btn-primary',
        size === 'small' && 'sapp-h-30px',
        size === 'middle' && 'sapp-h-40px',
        size === 'large' && 'sapp-h-45px'
      )}
      data-kt-menu-trigger={trigger}
      data-kt-menu-placement={placement}
      data-bs-toggle={toggle}
      data-bs-target={target}
    >
      <>
        <KTIcon iconName='watch' className='loader' iconType='duotone' />
        {classTitle ? <div className={classTitle}>{title}</div> : title}
      </>
    </button>
  )
}

export default ButtonLoading
