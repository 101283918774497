import { debounce } from 'lodash'
import HookFormSelectAntd from '../../base/select/HookFormSelectAntd'
import { ISubject, ISubjectList } from 'src/type/subject'
import HookFormTextField from '../../base/textfield/HookFormTextField'
import { SubjectAPI } from 'src/apis/subject'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { ICourseCategoies } from 'src/type/courses'
import { CoursesAPI } from 'src/apis/courses'
import { DEFAULT_SELECT_ALL } from 'src/constants'
import { Select } from 'antd'
import { UseFormReturn } from 'react-hook-form'
import { ISupportGroupDetail } from 'src/type/comment'
import { DepartmentAPI } from 'src/apis/department'
import { Department } from 'src/type/department'

const { Option } = Select

interface IProps {
  groupFrom: UseFormReturn<IForm>
  loading: boolean
  disable: boolean
  group?: ISupportGroupDetail
}

interface IForm {
  name: string
  display_name: string
  course_category_id?: string
  subject_id?: string
}

const CreateGroup = ({ groupFrom, loading, disable, group }: IProps) => {
  const initialValue = {
    page_index: 0,
    page_size: 0,
    total_pages: 0,
    total_records: 0,
  }

  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>({
    metadata: initialValue,
    course_categories: [],
  })

  const { control, setValue, watch } = groupFrom
  const [deparmentList, setDepartmentList] = useState<Department[]>([])

  const fetchDepartment = async (pageIndex?: number, pageSize?: number, params?: Object) => {
    try {
      const res = await DepartmentAPI.get({
        page_index: pageIndex ?? 1,
        page_size: pageSize ?? 50,
        params,
      })
      setDepartmentList(res.data)
    } catch (error) {}
  }

  useEffect(() => {
    fetchCourseCategory(1, 20, { text: group?.course_category?.name ?? '' })
    fetchDepartment(1, 50)
  }, [group])

  const fetchCourseCategory = async (pageIndex?: number, pageSize?: number, params?: Object) => {
    try {
      const res = await CoursesAPI.getCategory({
        page_index: pageIndex ?? 1,
        page_size: pageSize ?? 100,
        params,
      })
      setCourseCategory((prev: ICourseCategoies) => {
        return {
          ...prev,
          course_categories: [...prev.course_categories, ...res?.data.course_categories].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
      if (watch('course_category_id') && watch('course_category_id') !== 'all') {
        await getSubjects({ course_category_ids: watch('course_category_id') })
      }
    } catch (error) {}
  }

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects({
      subjects: [],
      meta: initialValue,
    })
    setValue('subject_id', '')
    if (watch('course_category_id') !== 'all') {
      await getSubjects({ course_category_id: watch('course_category_id') })
    }
  }

  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )

  /**
   * @description state lưu giá trị của subject
   */
  const [subjects, setSubjects] = useState<ISubjectList>({
    meta: initialValue,
    subjects: [],
  })
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        setSubjects((prev: ISubjectList) => {
          return {
            meta: res.data.meta,
            subjects: [...prev?.subjects, ...res?.data?.subjects],
          }
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async (params?: Object) => {
    const resMentor = await fetchSubjects(1, 50, params ?? {})
    setSubjects((prev: ISubjectList) => {
      return {
        meta: resMentor?.data?.meta ?? prev.meta,
        subjects: [...(resMentor?.data?.subjects ?? [])],
      }
    })
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ name: e, course_category_id: watch('course_category_id') })
    }
  }, 500)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  return (
    <div className='mt-15'>
      <HookFormTextField
        control={control}
        name='name'
        placeholder=''
        label='Group Name'
        required
        skeleton={loading}
      />
      <div className='mt-5'>
        <HookFormTextField
          control={control}
          name='display_name'
          placeholder=''
          label='Display Name'
          skeleton={loading}
          required
        />
      </div>
      <div className='mt-5'>
        <HookFormSelectAntd
          control={control}
          name='department_id'
          placeholder='Department'
          label='Department'
          disabled={disable}
          skeleton={loading}
          showSearch
          required
        >
          {deparmentList?.map((item: Department) => (
            <Option key={item.id} value={item.id}>
              {item?.name}
            </Option>
          ))}
        </HookFormSelectAntd>
      </div>
      <div className='mt-5'>
        <HookFormSelectAntd
          control={control}
          name='course_category_id'
          placeholder='Program'
          label='Program'
          disabled={disable}
          onChange={handleCourseCategoryChange}
          skeleton={loading}
          showSearch
          required
        >
          {(newCourseCategory ?? ([] as Array<{ label: string; value: string }[]>)).map(
            (course_category_ids) => (
              <Option key={course_category_ids.value} value={course_category_ids.value}>
                {course_category_ids.label}
              </Option>
            )
          )}
        </HookFormSelectAntd>
      </div>

      <div className='mt-5'>
        <HookFormSelectAntd
          control={control}
          name='subject_id'
          placeholder='Subject'
          label='Subject'
          skeleton={loading}
          disabled={disable}
          onSearch={(e?: string) => {
            if (e === undefined) {
              return
            }
            debounceSearchSubject(e)
          }}
          handleNextPage={(e: any) =>
            handleNextPageSubject({
              name: e,
              course_category_id: watch('course_category_id'),
            })
          }
          showSearch
          required
        >
          {subjectCourse?.map((subject) => (
            <Option key={subject.value} value={subject.value}>
              {subject.label}
            </Option>
          ))}
        </HookFormSelectAntd>
      </div>
    </div>
  )
}

export default memo(CreateGroup)
